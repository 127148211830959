
import './App.css';
import logo from './assets/img/logo-sitely-small.png'
function App() {
  return (
    <div className="App">

      <h1>Sitely 2.0 is coming soon!</h1>

    </div>
  );
}

export default App;
